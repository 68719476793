<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/home/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>签到活动</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="table-container">
      <i class="el-icon-tickets"></i>
      <span>签到活动列表</span>
      <el-table
        :data="signData"
        border
        stripe
        style="width: 100%"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column prop="title" label="签到名称" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.title }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="caption" label="签到描述" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.caption }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="acttype" label="签到类型" align="center">
          <template slot-scope="scope">
            <p>
              {{
                scope.row.acttype == 1
                  ? '课堂签到'
                  : scope.row.acttype == 2
                  ? '聚会签到'
                  : scope.row.acttype == 3
                  ? '会议签到'
                  : scope.row.acttype == 4
                  ? '讲座签到'
                  : '活动签到'
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="createtime"
          sortable
          label="创建时间"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.createtime }}</p>
          </template>
        </el-table-column>
        <!-- :formatter="formatter" -->
        <el-table-column prop="address" width="200" label="操作" align="center">
          <!-- slot-scope="scope" -->
          <template slot-scope="scope">
            <!-- @click="handleEdit(scope.$index, scope.row)" -->
            <el-button
              type="primary"
              size="mini"
              @click="handleShow(scope.$index, scope.row)"
              >查看签到环节</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next,jumper"
          :current-page.sync="formInline.pageNum"
          :page-size="formInline.pageSize"
          :page-sizes="[5, 10, 15]"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
const defaultFormInline = {
  pageNum: 1,
  pageSize: 5,
  university: null,
  academy: null,
  name: null
}
export default {
  name: 'signList',
  data() {
    return {
      signlist: [],
      signData: [],
      formInline: {
        university: '',
        academy: '',
        name: '',
        pageNum: 1,
        pageSize: 5
      },
      tid: this.$route.query.tid,
      // 分页
      total: 0,
      flag: null
    }
  },
  created() {
    // 1-普通 2-管理员
    this.flag = parseInt(sessionStorage.getItem('loginvalue'))
    this.tid = this.$route.query.tid
    window.sessionStorage.setItem('tid', this.tid)
    this.selectList()
  },
  mounted: function() {
    // this.selectList()
  },
  methods: {
    // 实现分页
    // 更改了每页大小
    handleSizeChange(val) {
      this.formInline.pageNum = 1
      this.formInline.pageSize = val

      // 请求对应的分页数据
      this.selectList()
    },
    // 更改了第几页
    handleCurrentChange(val) {
      this.formInline.pageNum = val
      this.selectList()
    },

    // 重置搜索表单
    handleResetSearch() {
      this.formInline = Object.assign({}, defaultFormInline)
    },
    // 查询签到环节
    handleShow(index, row) {
      window.sessionStorage.setItem('caption', row.caption)
      window.sessionStorage.setItem('title', row.title)
      window.sessionStorage.setItem('acttype', row.acttype)
      window.sessionStorage.setItem('createtime', row.createtime)
      this.$router.push({
        path: '/home/signList/signlinkList',
        query: { actId: row.actid }
      })
    },
    onSubmit() {},
    // 查询签到活动
    async selectList() {
      const { data: res } = await this.$http.get('act/selectList', {
        params: {
          tid: this.tid,
          pageNum: this.formInline.pageNum,
          pageSize: this.formInline.pageSize
        }
      })
      if (res != null) {
        console.log('我的', this.tid)
        this.signData = res.Activity
        this.total = res.total
      }
    }
  }
}
</script>

<style scoped>
.app-container {
  width: 100%;
  height: 100vh;
  padding-bottom: 300px;
  overflow: auto!important;
}
.input-width {
  width: 203px;
}
</style>
